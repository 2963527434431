import React from 'react'
import {Link} from 'react-router-dom'

import moment from 'moment'

import "../projects/css/project-tile.css"
import WeeklogUtils from '../../shared/WeeklogUtils'

const CurrentProject = ({project}) => {
    return (
        <div className="current-project" style={{backgroundImage: `url(${WeeklogUtils.getProjectImage(project).url})`}}>
            <Link className="hover-tile" to={`${process.env.PUBLIC_URL}/projects/${project.slug}`}>
                <div>
                    <h2 className="project-name">{project.name}</h2>
                    <p className="project-description">{project.description}</p>
                </div>
                <div className="project-last-entry">
                <strong>Last Entry:</strong>
                <p>{
                    project.entries && project.entries.length > 0
                        ? `${project.entries[0].summary} - ${moment(project.entries[0].timestamp, "x").fromNow()}`
                        : ""
                }</p>
                </div>
            </Link>
        </div>
    )
}

export default CurrentProject;