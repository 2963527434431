import React, {useState, useContext, useEffect} from 'react'
import {Redirect, Prompt} from 'react-router-dom'

import {Formik} from 'formik';
import {Form, Autocomplete, Datepicker, SubmitBtn, Input, Select, Textarea, Checkbox} from 'react-formik-ui'
import moment from 'moment';
import * as yup from 'yup'

import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth'
import ReactMarkdown from 'react-markdown'

import WeeklogUtils from '../../shared/WeeklogUtils'
import DataContext from '../../DataContext'
import UserContext from '../../UserContext'

import FirebaseConfig from'../../FirebaseConfig'

import '../../css/form.css'

const typeOptions = {
    "concept" : [
        {label: "", value: "invalid"},
        {label: "Experimentation", value: "experimentation"},
        {label: "Creation", value: "creation"},
    ],
    "experiment" : [
        {label: "", value: "invalid"},
        {label: "Experimentation", value: "experimentation"},
        {label: "Creation", value: "creation"},
    ],
    "prototype" : [
        {label: "", value: "invalid"},
        {label: "Elaboration", value: "elaboration"},
        {label: "Preparation", value: "preparation"},
        {label: "Stabilization", value: "stabilization"},
    ],
    "prerelease" : [
        {label: "", value: "invalid"},
        {label: "Elaboration", value: "elaboration"},
        {label: "Preparation", value: "preparation"},
        {label: "Stabilization", value: "stabilization"},
    ],
    "release" : [
        {label: "", value: "invalid"},
        {label: "Elaboration", value: "elaboration"},
        {label: "Stabilization", value: "stabilization"},
    ],
    "all" : [
        {label: "", value: "invalid"},
        {label: "Experimentation", value: "experimentation"},
        {label: "Stabilization", value: "stabilization"},
        {label: "Preparation", value: "preparation"},
        {label: "Elaboration", value: "elaboration"},
        {label: "Creation", value: "creation"},
    ],
    "experimentation" : [{label: "No valid effort types", value: "invalid"}],
    "stabilization" : [{label: "No valid effort types", value: "invalid"}],
    "preparation" : [{label: "No valid effort types", value: "invalid"}],
    "elaboration" : [{label: "No valid effort types", value: "invalid"}],
    "creation" : [{label: "No valid effort types", value: "invalid"}],
}

const AddEffort = ({match}) => {

    const {user} = useContext(UserContext);
    const {data, forceUpdateData} = useContext(DataContext);
    const [suggestions, setSuggestions] = useState({});
    const [finalEffort, setFinalEffort] = useState(null);
    const [targetProjectIndex, setTargetProjectIndex] = useState(-1);
    const [isShowingPreview, setIsShowingPreview] = useState(false);

    useEffect(() => {
        setSuggestions(data.projects.map(project => project.name));
    }, [data.projects]);

    useEffect(() => {
        if(match.params.pid) {
            setTargetProjectIndex(data.projects.findIndex(p => p.id === match.params.pid));
        }
    }, [data.projects, match])

    const handleSubmit = (values, {setSubmitting}) => {

        const doSubmitEffort = async(data, autoFinish, callback) => {
            firebase.auth().currentUser.getIdToken(true).then(idToken => {
                alert("The site is read only now, did you forget? :P");
                throw new Error("The site is read only now, did you forget? :P");
                
                const addEffort = async () => {
                    const newRef = await axios.post(`${FirebaseConfig.databaseURL}/efforts.json?auth=${idToken}`, data);
                    await axios.patch(`${FirebaseConfig.databaseURL}/projects/${data.project}/efforts.json?auth=${idToken}`, {[newRef.data.name] : true})
                    if(!autoFinish) {
                        await axios.patch(`${FirebaseConfig.databaseURL}/projects/${data.project}.json?auth=${idToken}`, {"status" : data.type})
                    } else {
                        await axios.patch(`${FirebaseConfig.databaseURL}/projects/${data.project}.json?auth=${idToken}`, {"version" : data.newVersionNumber});

                        //TODO - this is super error prone if I ever change the state machine of states and efforts...
                        switch(data.type) {
                            case "creation":
                                await axios.patch(`${FirebaseConfig.databaseURL}/projects/${data.project}.json?auth=${idToken}`, {"status" : "prototype"})
                                break;
                            case "elaboration":
                                await axios.patch(`${FirebaseConfig.databaseURL}/projects/${data.project}.json?auth=${idToken}`, {"status" : "prerelease"})
                                break;
                            case "preparation":
                                await axios.patch(`${FirebaseConfig.databaseURL}/projects/${data.project}.json?auth=${idToken}`, {"status" : "release"})
                                break;
                            case "experimentation":
                                await axios.patch(`${FirebaseConfig.databaseURL}/projects/${data.project}.json?auth=${idToken}`, {"status" : "experiment"})
                                break;
                            default:
                                break;
                        }
                    }
                    setFinalEffort(newRef.data.name);
                    forceUpdateData();
                }
                
                addEffort();
            }).catch(err => {
                console.log("Error submitting", err);
                callback();
            })
        }

        setSubmitting(true);


        const projectIndex = data.projects.findIndex(project => project.name === values.project);
        if(projectIndex === -1) {
            console.log("Invalid project, somehow?");
            setSubmitting(false);
            return;
        }

        const finalStartTimestamp = moment(values.startDate).startOf("day")
            .add(moment().hour(), "hours")
            .add(moment().minute(), "minutes")
            .add(moment().second(), "seconds").valueOf();

        const finalEndTimestamp = moment(values.endDate).startOf("day")
            .add(moment().hour(), "hours")
            .add(moment().minute(), "minutes")
            .add(moment().second(), "seconds").valueOf();

        const newEffortData = {
            project: data.projects[projectIndex].id,
            type: values.type,
            statusBefore: data.projects[projectIndex].status,
            summary: values.summary,
            slug: WeeklogUtils.getUniqueSlug(data, values.summary, data.projects[projectIndex]),
            content: values.content,
            startDate: finalStartTimestamp,
            endDate: values.autoFinish ? finalEndTimestamp : false,
            newVersionNumber: values.autoFinish ? values.newVersionNumber : false
        }

        doSubmitEffort(newEffortData, values.autoFinish, () => setSubmitting(false));
    }

    const getSchema = () => {
        return yup.object().shape({
            project: yup.string().required("A project is required")
                .oneOf(data.projects.map(project => project.name), "Invalid project")
                .test('project', "Project already has an effort underway", val => {
                    return typeOptions[data.projects.find(project => project.name === val).status] !== null;
                }),
            type: yup.string().required("A type is required")
                .test('project', "Invalid type", val => {
                    const foundProject = data.projects[targetProjectIndex];
                    if(!foundProject) return false;
                    if(!typeOptions[foundProject.status]) return false;
                    if(typeOptions[foundProject.status].slice(-1)[0].value === "invalid") return false;

                    return true;
                }).notOneOf(["invalid", ""], "A type is required"),
            summary: yup.string().required("A summary is required"),
            content: yup.string().notRequired(),
            startDate: yup.date().required("A start date is required"),
            autoFinish: yup.boolean(),
            endDate: yup.date().when('autoFinish', {
                is: true,
                then: yup.date().required("An end date is required if using autoFinish"),
                otherwise: yup.date()
            }),
            newVersionNumber: yup.string().when('autoFinish', {
                is: true,
                then: yup.string().required("A new version number is required if using autoFinish"),
                otherwise: yup.string().notRequired()
            })
        });
    }

    if(!user) return <Redirect to={`${process.env.PUBLIC_URL}/`} />
    if(finalEffort !== null) {
        return <Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />
    }

    const handleChange = e => {
        if(e.target.id !== "project") return;

        setTargetProjectIndex(data.projects.findIndex(p => p.name === e.target.value));
    }


    return (
        <div className="container">
            <h1>Add Effort</h1>
            <Formik
                initialValues={{
                    project: match.params.pid ? data.projects.find(p => p.id === match.params.pid).name : "",
                    type: "",
                    summary: "",
                    content: "",
                    autoFinish: false,
                    startDate: moment().startOf("day").add(12, "hour").toDate(), //to prevent dirty from constantly changing,
                    endDate: moment().startOf("day").add(12, "hour").toDate(), //to prevent dirty from constantly changing,
                    newVersionNumber: ""
                }}
                validationSchema={getSchema}
                onSubmit={handleSubmit}
            >{({values, dirty, isSubmitting, setFieldOption}) => (
                <Form onChange={handleChange}>
                    <Prompt when={dirty} message="You have unsaved changes. If you leave now, those changes will be lost." />
                    <Autocomplete name="project" label="Project" suggestions={suggestions} autoComplete="off" />
                    {
                        targetProjectIndex  === -1
                            ? <Input name="type" label="Type" disabled={true} placeholder="Select a Project" />
                            : <Select name="type" label="Type" options={typeOptions[values.autoFinish ? "all" : data.projects[targetProjectIndex].status]} />
                    }
                    <Input name="summary" label="Summary" autoComplete="off" />
                    <Datepicker name="startDate" label="Start Date" />
                    <div className="content-previewable-wrapper">
                        <div className="content-previewable-label">
                            <span>Content</span>
                            <button type="button" onClick={() => setIsShowingPreview(!isShowingPreview)}>{isShowingPreview ? "Hide Preview" : "Show Preview"}</button>
                        </div>
                        {isShowingPreview ? <div className="content-preview"><ReactMarkdown source={values.content} /></div> : <Textarea name="content" label="" autoComplete="off" /> }
                    </div>
                    <Checkbox name="autoFinish" label="Auto Finish" />
                    <div className={values.autoFinish ? null : "hidden"}>
                        <Datepicker name="endDate" label="End Date" />
                        <Input name="newVersionNumber" label="New Version Number" autoComplete="off" />
                    </div>
                    <div className="form-element">
                        <div className="buttons-wrapper">   
                            <SubmitBtn disabled={isSubmitting} children={isSubmitting ? "Submitting..." : "Submit"} />
                        </div>
                    </div>
                </Form>
            )}</Formik>
        </div>
    )
}

export default AddEffort;