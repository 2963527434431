import React from 'react'

import {Link} from 'react-router-dom'

import ReactMarkdown from 'react-markdown'

import ImageGallery from '../common/ImageGallery'

import './css/entry-list-item.css'

const EntryListItem = ({entry, project}) => {

    if(!entry || !project) return <div></div>

    return (
        <div className="entry-list-item">
            <h4>
                {project.name}
                {" - "}
                {!entry.label 
                    ? null
                    : entry.label === "note" 
                        ? "📝 " 
                        : entry.label === "elaboration" 
                            ? "🎨 " 
                            : "🧪 "
                }
                <Link to={`${process.env.PUBLIC_URL}/entries/${entry.slug}`}>
                    {entry.summary}
                </Link>
            </h4>
            <div className="entry-list-item-content">
                <ReactMarkdown source={entry.content} />
            </div>
            {entry.images ? <ImageGallery images={entry.images} width={window.innerWidth > 640 ? 640 : (window.innerWidth - 60)} /> : null}
        </div>
    )
}

export default EntryListItem;