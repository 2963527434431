const remapObjectKeysToList = (list, key) => {
    const output = {};
    list.forEach(item => output[item[key]] = true);
    return output;
}

const remapMediaItem = (mediaItem) => {
    return {
        caption: mediaItem.caption || "",
        name: mediaItem.filename,
        ref: mediaItem.s3Key,
        size: mediaItem.size,
        type: mediaItem.fileType,
        url: mediaItem.uploadUrl,
    }
}
const filterMediaItem = mediaItem => mediaItem.type === "UPLOAD_IMAGE";
const remapMediaList = (media) => !media ? [] : media.map(remapMediaItem);
const dateStrToTs = (dateStr) => new Date(dateStr).valueOf();

export const PrepareV5data = (v5data) => {
    return Object.values(v5data.projects).map(p => {
        p.efforts = p.effortIds?.map(eid => Object.values(v5data.efforts).find(e => e.id === eid)) || [];
        p.efforts.forEach(e => {
            e.entries = e.entryIds?.map(eid => Object.values(v5data.entries).find(en => en.id === eid)) || [];
        });
        return p;
    });
}

export const V5toV2Project = (v5project) => {
    const allEfforts = v5project.efforts || [];
    const allEntries = [];
    allEfforts.forEach(e => {
        if(e.entries) allEntries.push(...e.entries);
    });
    if(!v5project.media) v5project.media = [];
    let heroImage = v5project.media.filter(filterMediaItem).length > 0 ? remapMediaItem(v5project.media.filter(filterMediaItem)[0]) : null;
    if(!heroImage) {
        for(let i = 0; i < allEntries.length; i++) {
            if(allEntries[i].media && allEntries[i].media.filter(filterMediaItem).length > 0) {
                heroImage = remapMediaItem(allEntries[i].media.filter(filterMediaItem)[0]);
                break;
            }
        }
    }
    const v2project = {
        content: v5project.content,
        description: v5project.tagline,
        downloads: [], //no downloads in v5...
        efforts: remapObjectKeysToList(allEfforts, "id"),
        entries: remapObjectKeysToList(allEntries, "id"),
        featuredImages: remapMediaList(v5project.media),
        heroImage: heroImage,
        idea: v5project.id.toString(),
        isTrashed: v5project.isTrashed,
        name: v5project.name,
        slug: v5project.slug,
        status: "prototype",
        tags: v5project.tags || [],
        timestamp: dateStrToTs(v5project.createdAt),
        transitions: false,
        version: "1",
        visibility: v5project.visibility === "TOPSECRET" ? "top-secret" : v5project.visibility.toLowerCase(),
        key: v5project.id.toString(),
    }
    const v2efforts = {};
    allEfforts.forEach(e => {
        v2efforts[e.id.toString()] = {
            content: e.content || "",
            startDate: e.entries && e.entries.length > 0 ? dateStrToTs(e.entries[0].createdAt) : dateStrToTs(e.createdAt),
            endDate: e.entries && e.entries.length > 0 ? dateStrToTs(e.entries.slice(-1)[0].createdAt) : dateStrToTs(e.createdAt) + 1000*60*60*24,
            newVersionNumber: "1",
            project: v5project.id.toString(),
            slug: e.slug,
            statusBefore: "prototype",
            summary: e.title,
            type: "experimentation",
        }
    });

    const v2entries = {};
    allEntries.forEach(e => {
        if(!e.media) e.media = [];
        const imageCount = e.media.filter(m => m.type === "UPLOAD_IMAGE").length;
        const videoCount = e.media.filter(m => m.type === "UPLOAD_VIDEO").length;
        v2entries[e.id.toString()] = {
            content: e.content || "",
            images: remapMediaList(e.media),
            key: e.id.toString(),
            label: "experimentation",
            metadata: { "character-count": e.content.length, "image-count": imageCount, "video-count": videoCount, "word-count": e.content.split(" ").length },
            project: v5project.id.toString(),
            slug: e.slug,
            summary: e.title,
            timestamp: dateStrToTs(e.createdAt),
        }
    });

    const v2idea = {
        name: v2project.name,
        slug: v2project.slug,
        projects: { [v5project.id.toString()]: true },
        timestamp: v2project.timestamp,
        key: v5project.id.toString(),
    };
    
    return {
        v2idea, v2project, v2efforts, v2entries
    };
}