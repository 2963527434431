import React, {useContext} from 'react'

import moment from 'moment'

import DataContext from '../../DataContext'

import EntryListItem from './EntryListItem'

const EntryDay = ({entries}) => {

    const {data} = useContext(DataContext);

    return (
        <div className="entry-day">
            <h3>
                {moment(entries[0].timestamp, "x").format("dddd")} {moment(entries[0].timestamp, "x").format("Do MMMM YYYY")}
            </h3>
            { entries.map(entry => {
                return (
                    <EntryListItem key={entry.id} entry={entry} project={data.projects[entry.project.index]} />
                )
            })}
            <hr/>
        </div>
    )
}

export default EntryDay;