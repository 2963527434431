import React from 'react';

import TimelineCalcs from './TimelineCalcs'
import TimelineEntry from './TimelineEntry'

import "./css/timeline.css"

const TimelineProject = ({project, index, width, globalTimeBounds, onHoverOn, onHoverOff}) => {

    const projectList = project.entries.filter(entry => !entry.isTrashed).map(entry => {
        return (
            <TimelineEntry 
                key={entry.id}
                backgroundColor={`hsl(${(index * 720) % 360}, 80%, 70%)`}
                entry={entry} 
                globalTimeBounds={globalTimeBounds}
                hoverOn={onHoverOn}
                hoverOff={onHoverOff} />
        )
    })

    return (
        <div className="timeline-project" style={{
            width: `${width}%`,
            height: TimelineCalcs.getHeight(globalTimeBounds),
        }}>
            <p className="timeline-project-title-top" style={{
                color: `hsl(${(index * 720) % 360}, 80%, 70%)`,
                top: `${TimelineCalcs.getEntryPosition(project.entries[0], globalTimeBounds)}px`,
                right: window.innerWidth < 640 
                    ? `calc(3em + ${100 * (1-index) * ((window.innerWidth - 48)/window.innerWidth)}%)`
                    : `calc(${(window.innerWidth - 1000) * 0.5}px + ${100 * (1-index) * (1000/window.innerWidth)}%)`
            }}>{project.name}</p>
            {
                // <p className="timeline-project-title-bottom" style={{
                //     color: `hsl(${(index * 720) % 360}, 80%, 70%)`,
                //     top: `${164 + 5000 * ((globalTimeBounds.max - project.entries[project.entries.length - 1].timestamp) / (globalTimeBounds.max - globalTimeBounds.min))}px`,
                //     left: `calc(${(window.innerWidth - 1000) * 0.5}px + ${100 * (index) * (1000/window.innerWidth)}%)`
                // }}>{project.name}</p>
            }
            <div className="timeline-line" style={{
                top: `${TimelineCalcs.getEntryPosition(project.entries[0], globalTimeBounds)}px`,
                backgroundColor: `hsl(${(index * 720) % 360}, 80%, 70%)`,
                height: `${TimelineCalcs.getProjectHeight(project, globalTimeBounds)}px`
            }}>
            </div>
            {projectList}
        </div>
    )
}

export default TimelineProject;