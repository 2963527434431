import React, {useMemo, useState, useEffect} from 'react';
import {Route, Switch} from 'react-router-dom'

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'

import Navbar from './components/nav/Navbar'
import MobileNavbar from './components/nav/MobileNavbar'
import LoadData from './components/loading/LoadData'
import ScrollToTop from './components/common/ScrollToTop'
import Footer from './components/nav/Footer'

import Landing from './components/landing/Landing'
import Timeline from './components/timeline/Timeline'

import Projects from './components/projects/Projects'
import AddProject from './components/projects/AddProject'
import EditProject from './components/projects/EditProject'
import ProjectDetail from './components/projects/ProjectDetail'

import Entries from './components/entries/Entries'
import AddEntry from './components/entries/AddEntry'
import EditEntry from './components/entries/EditEntry'
import EntryDetail from './components/entries/EntryDetail'
import LabelEntries from './components/entries/LabelEntries'

import Efforts from './components/efforts/Efforts'
import AddEffort from './components/efforts/AddEffort'
import EditEffort from './components/efforts/EditEffort'
import CompleteEffort from './components/efforts/CompleteEffort'

import DownloadDetail from './components/downloads/DownloadDetail'
import AddDownload from './components/downloads/AddDownload'
import EditDownload from './components/downloads/EditDownload'

import Login from './components/auth/Login'
import Logout from './components/auth/Logout'

import Dashboard from './components/dashboard/Dashboard'
import DashboardProject from './components/dashboard/DashboardProject'

import DataContext from './DataContext'
import UserContext from './UserContext'
import StorageContext from './StorageContext'

import FirebaseConfig from './FirebaseConfig'

function App() {

  const [data, updateData] = useState({
    entries:[],
    projects:[],
    users:[],
    ideas:[],
    efforts: [],
    landing: {},
    tags: [],
    downloads: [],
    config: {},
  });
  const [user, updateUser] = useState(null);
  const [storage, updateStorage] = useState(null);
  const [rawUser, updateRawUser] = useState(null);
  const [loading, updateLoading] = useState("pre-auth");
  const [idToken, updateIdToken] = useState("");
  const [forceReset, setForceReset] = useState(0);

  const forceUpdateData = () => {
    updateLoading("get-data");
    setForceReset(Math.random());
  }

  const dataProviderValue = useMemo(() => ({data, updateData, forceUpdateData}), [data, updateData]);
  const userProviderValue = useMemo(() => ({user, updateUser}), [user, updateUser]);
  const storageProviderValue = useMemo(() => ({storage, updateStorage}), [storage, updateStorage]);

  useEffect(() => {
    firebase.initializeApp(FirebaseConfig);
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

    firebase.auth().signOut();

    firebase.auth().onAuthStateChanged(user => {
      if(!user) {
        updateIdToken("");
        updateRawUser(null);
        updateLoading("get-data");
      } else user.getIdToken().then(token => {
        updateIdToken(token);
        updateRawUser(user);
        updateLoading("get-data");
      })
    });

    updateStorage(firebase.storage());
  }, []);

  useEffect(() => {
      if(!rawUser && user !== null) {
        updateUser(null);
        return;
      }
      const foundUser = rawUser ? data.users.find(user => user.email === rawUser.email) : null;
      if(!foundUser) {
        updateUser(null);
        return;
      }
      if((foundUser && !user) || (foundUser.id !== user.id)) {
        updateUser(foundUser);
      }
  }, [rawUser, data.users, user])

  const [currentNavbar, setCurrentNavbar] = useState(null);
  React.useEffect(() => {
    function handleResize() {
      setCurrentNavbar(
        window.innerWidth < 640
          ? <MobileNavbar showButtons={loading==="has-data"} />
          : <Navbar showButtons={loading === "has-data"}/>
      )
    }

    window.addEventListener('resize', handleResize)
    handleResize();
  }, [loading]);

  // Populate site template values
  React.useEffect(() => {
      // HTML title
      if(data.config.htmlTitle) {
          document.title = data.config.htmlTitle;
      }

      // Meta description
      if(data.config.metaDescription) {
          document.querySelector('meta[name="description"]').setAttribute("content", data.config.metaDescription);
      }

      // Favicon
      if(data.config.faviconUrl) {
          document.querySelector('link[rel="icon"]').setAttribute("href", data.config.faviconUrl);
      }

      // Apple touch icon
      if(data.config.appleTouchIconUrl) {
          document.querySelector('link[rel="apple-touch-icon"]').setAttribute("href", data.config.appleTouchIconUrl);
      }
  });

  return (
    <div className="App">
      <DataContext.Provider value={dataProviderValue}>
      <UserContext.Provider value={userProviderValue}>
      <StorageContext.Provider value={storageProviderValue}>
      <ScrollToTop />
      {currentNavbar}

        <div className="main">
        {loading === "pre-auth" ? null : <LoadData updateParentLoading={updateLoading} userEmail={rawUser ? rawUser.email : null} forceReset={forceReset} idToken={idToken} />}
          {loading !== "has-data" ? null : (
          <Switch>
            <Route exact path={`${process.env.PUBLIC_URL}/`} component={Landing} />
            <Route path={`${process.env.PUBLIC_URL}/timeline`} component={Timeline} />

            <Route exact path={`${process.env.PUBLIC_URL}/entries`} component={Entries} />
            <Route exact path={`${process.env.PUBLIC_URL}/entries/add`} component={AddEntry} />
            <Route exact path={`${process.env.PUBLIC_URL}/entries/edit/:eid`} component={EditEntry} />
            <Route path={`${process.env.PUBLIC_URL}/entries/label`} component={LabelEntries} />
            <Route path={`${process.env.PUBLIC_URL}/entries/:eslug`} component={EntryDetail} />

            <Route exact path={`${process.env.PUBLIC_URL}/projects`} component={Projects} />
            <Route path={`${process.env.PUBLIC_URL}/projects/add`} component={AddProject} />
            <Route path={`${process.env.PUBLIC_URL}/projects/edit/:pid`} component={EditProject} />
            <Route exact path={`${process.env.PUBLIC_URL}/projects/:pslug`} component={ProjectDetail} />
            <Route path={`${process.env.PUBLIC_URL}/projects/:pslug/:page`} component={ProjectDetail} />

            <Route exact path={`${process.env.PUBLIC_URL}/efforts`} component={Efforts} />
            <Route exact path={`${process.env.PUBLIC_URL}/efforts/add`} component={AddEffort} />
            <Route path={`${process.env.PUBLIC_URL}/efforts/add/:pid`} component={AddEffort} />
            <Route path={`${process.env.PUBLIC_URL}/efforts/edit/:eid`} component={EditEffort} />
            <Route path={`${process.env.PUBLIC_URL}/efforts/complete/:eid`} component={CompleteEffort} />

            <Route exact path={`${process.env.PUBLIC_URL}/downloads/:dslug`} component={DownloadDetail} />
            <Route path={`${process.env.PUBLIC_URL}/downloads/add/:pid`} component={AddDownload} />
            <Route path={`${process.env.PUBLIC_URL}/downloads/edit/:did`} component={EditDownload} />

            <Route path={`${process.env.PUBLIC_URL}/login`} component={Login} />
            <Route path={`${process.env.PUBLIC_URL}/logout`} component={Logout} />
            
            <Route exact path={`${process.env.PUBLIC_URL}/dashboard`} component={Dashboard} />
            <Route exact path={`${process.env.PUBLIC_URL}/dashboard/project/:pid`} component={DashboardProject} />
            <Route path={`${process.env.PUBLIC_URL}/dashboard/project/:pid/:page`} component={DashboardProject} />
          </Switch>
        )}
        {loading !== "has-data" ? null : <Footer />}
        </div>
      </StorageContext.Provider>
      </UserContext.Provider>
      </DataContext.Provider>
    </div>
  );
}

export default App;
